import debounce from 'lodash/debounce';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';
import Viewport from '../core/Viewport';

export default el => {

    const ariaLabel = el.getAttribute('aria-label');
    const target = el.getAttribute('href');

    const { upLabel, upTarget } = el.dataset;

    let direction = 'down';

    const makeAnchor = dir => {
        if (dir === direction) {
            return;
        }
        direction = dir;
        if (direction === 'up') {
            el.setAttribute('aria-label', upLabel);
            el.setAttribute('href', upTarget);
            el.classList.add('is-up');
        } else {
            el.setAttribute('aria-label', ariaLabel);
            el.setAttribute('href', target);
            el.classList.remove('is-up');
        }
    };

    const onScroll = () => {
        if (Viewport.scrollTop >= 150) {
            makeAnchor('up');
        } else {
            makeAnchor('down');
        }
    };

    const scrollHandler = debounce(onScroll, 100, { leading: false, trailing: true });

    const init = () => {
        window.addEventListener('scroll', scrollHandler);
        Dispatch.emit(COMPONENT_INIT);
        onScroll();
    };

    const destroy = () => {
        window.removeEventListener('scroll', scrollHandler);
    };

    return {
        init,
        destroy
    };

};
