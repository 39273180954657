/*
 *   Lazysizes config
 *
 */

window.lazySizesConfig = {
    init: false,
    expand: 1000
};

/* global LEGACY_BUILD */
/* eslint-disable global-require */
if (LEGACY_BUILD) {
    require('lazysizes/plugins/bgset/ls.bgset');
    require('lazysizes/plugins/respimg/ls.respimg');
}
/* eslint-enable global-require */

const lazySizes = require('lazysizes');

export default {
    init: () => lazySizes.init()
};
