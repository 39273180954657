/*
*   ~ Project event keys go in here ~
*
*   Usage in components:
*
*   import Events from '@vaersaagod/tools/Events';
*   import * as eventKeys from '../lib/events';
*   Events.publish(eventKeys.SOME_EVENT, 'This ES6 shit is wack');
*
 */

export const COMPONENT_INIT = 'COMPONENT_INIT';
export const MENU_OPEN = 'MENU_OPEN';
export const MENU_CLOSE = 'MENU_CLOSE';
export const BG_COLOR_CHANGE = 'BG_COLOR_CHANGE';
export const PROGRAMMATIC_SCROLL_START = 'PROGRAMMATIC_SCROLL_START';
export const UNLOAD = 'UNLOAD';
