import Flickity from 'flickity';
import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { isTouch, reduceMotion } from '../lib/helpers';
import { COMPONENT_INIT } from '../lib/events';

export default el => {

    const $el = $(el);
    const $slides = $(el.children);

    let flkty = null;
    let isWindowScrollingTimer = null;
    let startAutoScrollTimer = null;
    let autoscrollTween = null;

    let isIntersecting = false;
    let isDragging = false;
    let hasDragged = false;
    let isMouseOver = false;
    let isPointerDown = false;
    let isFocused = false;

    let observer = null;
    let isAutoScrolling = false;

    let viewportW = Viewport.width;

    const stopAutoScroll = () => {
        if (startAutoScrollTimer) {
            clearTimeout(startAutoScrollTimer);
            startAutoScrollTimer = null;
        }
        if (autoscrollTween) {
            autoscrollTween.kill();
            autoscrollTween = null;
        }
        isAutoScrolling = false;
    };

    const maybeAutoScroll = (delay = 0) => {

        stopAutoScroll();

        if (!flkty || !isIntersecting || isDragging || isMouseOver || isFocused) {
            return;
        }

        autoscrollTween = gsap.to(flkty, {
            x: flkty.x - 100,
            duration: 1,
            ease: 'none',
            delay,
            onUpdate() {
                if (!flkty) {
                    stopAutoScroll();
                    return;
                }
                flkty.positionSlider(flkty.x);
            },
            onComplete: () => {
                maybeAutoScroll();
            }
        });

        isAutoScrolling = true;

    };

    const destroyFlickity = () => {
        stopAutoScroll();
        if (!flkty) {
            return;
        }
        flkty.destroy();
        flkty = null;
        $slides.off('focusin mouseenter mouseover mousemove');
        $el.off('mouseenter mouseleave');
        gsap.set($slides.get(), { clearProps: 'all' });
    };

    const initFlickity = () => {

        if (flkty) {
            return;
        }

        flkty = new Flickity(el, {
            contain: false,
            dragThreshold: 15,
            cellAlign: 'center',
            prevNextButtons: false,
            groupCells: false,
            pageDots: false,
            freeScroll: true,
            freeScrollFriction: 0.045,
            setGallerySize: true,
            resize: false,
            wrapAround: true,
            accessibility: false,
            on: {
                pointerDown: () => {
                    isPointerDown = true;
                },
                pointerUp: () => {
                    isPointerDown = false;
                },
                dragStart: () => {
                    if (!isPointerDown) {
                        return;
                    }
                    stopAutoScroll();
                    isDragging = true;
                    hasDragged = true;
                    document.ontouchmove = e => e.preventDefault();
                    $slides.css({ pointerEvents: 'none' });
                },
                dragEnd: () => {
                    if (!isDragging) {
                        return;
                    }
                    isDragging = false;
                    document.ontouchmove = () => true;
                    $slides.css({ pointerEvents: '' });
                    // if (isTouch()) { // TODO re-enable when we've got smooth/eased start/stop
                    //     maybeAutoScroll(3);
                    // }
                },
                ready() {
                    maybeAutoScroll();
                }
            }
        });

        $el.on('mouseleave', () => {
            if (isTouch()) {
                return;
            }
            isMouseOver = false;
            maybeAutoScroll();
        });

        $el.on('mouseenter', () => {
            if (isWindowScrollingTimer || isTouch()) {
                return;
            }
            isMouseOver = true;
            stopAutoScroll();
        });

        $slides.on('focusin', e => {
            stopAutoScroll();
            isFocused = true;
            flkty.selectCell(e.target, true, true);
        });

        $slides.on('focusout', 'a', () => {
            isFocused = false;
            maybeAutoScroll();
        });

        setTimeout(() => {
            gsap.set(el, { opacity: 1 });
        }, 0);

    };

    const onResize = () => {
        const { width } = Viewport;
        if (width === viewportW) {
            return;
        }
        viewportW = width;
        if (flkty) {
            flkty.resize();
        }
    };

    const onScroll = () => {
        if (isWindowScrollingTimer) {
            clearTimeout(isWindowScrollingTimer);
            isWindowScrollingTimer = null;
        }
        if (!isIntersecting) {
            return;
        }
        isWindowScrollingTimer = setTimeout(() => {
            clearTimeout(isWindowScrollingTimer);
            isWindowScrollingTimer = null;
        }, 120);
    };

    const init = () => {
        observer = new IntersectionObserver(entries => {
            isIntersecting = entries[0].isIntersecting;
            if (isIntersecting) {
                initFlickity();
                maybeAutoScroll();
            } else {
                isMouseOver = false;
                isDragging = false;
                hasDragged = false;
                isFocused = false;
                isPointerDown = false;
                stopAutoScroll();
            }
        });
        Viewport.on('scroll', onScroll);
        observer.observe(el);
        Viewport.on('resize', onResize);
        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        Viewport.off('scroll', onScroll);
        Viewport.off('resize', onResize);

        if (observer) {
            observer.disconnect();
            observer = null;
        }

        destroyFlickity();
    };

    return {
        init,
        destroy
    };
};
