import gsap from 'gsap';
import serialize from 'form-serialize';
import $ from '../core/Dom';
import request from '../core/request';

export default form => {

    const $form = $(form);
    const errorMessage = $form.find('[data-error]').get(0);
    const receipt = $form.find('[data-receipt]').get(0);
    const submitBtn = $form.find('button[type="submit"]').get(0);

    let isSubmitting = false;

    const disableSubmit = () => {
        submitBtn.setAttribute('aria-disabled', 'true');
    };

    const enableSubmit = () => {
        submitBtn.removeAttribute('aria-disabled');
    };

    const reset = () => {
        // Clear input fields
        $form.find('input:not([type="hidden"]),textarea').get().forEach(input => {
            if (['radio', 'checkbox'].indexOf(input.getAttribute('type')) > -1) {
                input.checked = false;
            } else {
                input.value = null;
            }
        });
        // Hide receipt and show submit button
        gsap.timeline({
            onComplete() {
                enableSubmit();
                receipt.hidden = true;
                const { activeElement } = document;
                if (!activeElement || activeElement === receipt || activeElement.offsetParent === null) {
                    submitBtn.focus();
                }
            }
        })
            .to(receipt, { opacity: 0, scale: 0.9, duration: 0.3 }, 0)
            .to(submitBtn, { opacity: 1, scale: 1, duration: 0.3 }, 0.15);
        receipt.removeEventListener('blur', reset);
    };

    const showReceipt = () => {
        receipt.hidden = false;
        receipt.focus();
        gsap.timeline()
            .to(submitBtn, { opacity: 0, scale: 0.9, duration: 0.3 }, 0)
            .fromTo(receipt, { opacity: 0, scale: 0.9 }, { opacity: 1, scale: 1, duration: 0.3 }, 0.15);
        receipt.addEventListener('blur', reset);
    };

    const showGenericError = () => {
        errorMessage.hidden = false;
        errorMessage.focus = true;
    };

    const clearErrors = () => {
        if (errorMessage) {
            errorMessage.hidden = true;
        }
        $form.find('[data-errors]').each(node => {
            node.textContent = '';
            node.hidden = true;
        });
        $form.find('[data-input].error').removeClass('error');
    };

    const getErrorNames = (errors, prefix = '') => Object.keys(errors || {}).reduce((carry, key) => {
        if (typeof errors[key] === 'object' && !Array.isArray(errors[key])) {
            return {
                ...carry,
                ...getErrorNames(errors[key], key)
            };
        }
        const name = prefix ? `${prefix}[${key}]` : key;
        return {
            ...carry,
            [name]: errors[key]
        };
    }, {});

    const showErrors = (errors = []) => {

        clearErrors();

        const errorsByName = getErrorNames(errors);
        const errorNames = Object.keys(errorsByName);

        if (!errorNames.length) {
            showGenericError();
            return;
        }

        errorNames.forEach(name => {
            const input = $form.find(`[data-input="${name}"]`).get(0);
            if (input) {
                input.classList.add('error');
            }
            const errorsDiv = $form.find(`[data-errors="${name}"]`).get(0);
            if (errorsDiv) {
                errorsDiv.hidden = false;
                $(errorsDiv).html([].concat(errorsByName[name]).join('<br/>'));
            }
        });

        const firstErrorInput = $form.find('[data-input].error').find('input:not([type="hidden"]),textarea,select').get(0);
        if (firstErrorInput) {
            firstErrorInput.focus();
        }

        enableSubmit();

    };

    const onSubmit = e => {

        e.preventDefault();

        if (isSubmitting || submitBtn.hasAttribute('aria-disabled')) {
            return;
        }

        clearErrors();

        isSubmitting = true;
        disableSubmit();

        form.classList.add('submitting');

        const data = serialize($form.get(0));
        const url = window.location.href.split('?')[0].split('#')[0];

        request
            .post(url)
            .accept('application/json')
            .send(data)
            .then(({ status, body }) => {
                if (status !== 200) {
                    throw new Error();
                }
                const { success, errors = [] } = body || {};
                clearErrors();
                if (success) {
                    showReceipt();
                } else {
                    showErrors(errors);
                }
            })
            .catch(error => {
                console.error(error);
                showErrors();
            })
            .then(() => {
                isSubmitting = false;
                form.classList.remove('submitting');
                enableSubmit();
            });
    };

    const init = () => {
        $form.on('submit', onSubmit);
        $form.find('a[href]:not([href^="#"])').each(link => {
            $(link).attr({
                target: '_blank',
                rel: 'noopener noreferrer'
            });
        });
    };

    const destroy = () => {
        $form.off('submit', onSubmit);
    };

    return {
        init,
        destroy
    };
};
