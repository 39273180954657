import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { BG_COLOR_CHANGE, COMPONENT_INIT, MENU_CLOSE, MENU_OPEN, UNLOAD } from '../lib/events';

export default el => {

    const $el = $(el);
    const inner = el.firstElementChild;
    const main = $('#main').get(0);
    const bg = $el.find('[data-bg]').get(0);

    let { scrollTop: prevScrollTop } = Viewport;

    let height = $(inner).height();

    let isSticky = false;
    let menuOpen = false;
    let isHidden = false;
    let isMini = false;

    let heroObserver;
    let heroIntersecting = false;

    let bgColor = null;

    let { width: viewW, height: viewH } = Viewport;

    const getCurrentColor = () => Array.from(bg.classList).filter(className => className.startsWith('bg-'))[0] || null;

    const defaultColor = getCurrentColor();

    const show = (tween = true) => {
        if (!isHidden) {
            return;
        }
        isHidden = false;
        gsap.killTweensOf(inner);
        if (tween) {
            gsap.to(inner, { yPercent: 0, duration: 0.3 });
        } else {
            gsap.set(inner, { yPercent: 0 });
        }
    };

    const hide = (tween = true) => {
        if (isHidden) {
            return;
        }
        isHidden = true;
        gsap.killTweensOf(inner);
        if (tween) {
            gsap.to(inner, { yPercent: -100, duration: 0.3 });
        } else {
            gsap.set(inner, { yPercent: -100 });
        }
    };

    const stick = () => {
        if (isSticky) {
            return;
        }
        isSticky = true;
        el.classList.add('is-fixed');
    };

    const unstick = () => {
        if (!isSticky) {
            return;
        }
        isSticky = false;
        el.classList.remove('is-fixed');
    };

    const minify = () => {
        if (isMini) {
            return;
        }
        isMini = true;
        el.classList.add('is-mini');
    };

    const unminify = () => {
        if (!isMini) {
            return;
        }
        isMini = false;
        el.classList.remove('is-mini');
    };

    let scrollRaf = null;

    const onScroll = (tween = true, force = false) => {
        if (scrollRaf) {
            clearTimeout(scrollRaf);
        }
        scrollRaf = setTimeout(() => {
            scrollRaf = null;
            const { scrollTop } = Viewport;
            if (menuOpen) {
                prevScrollTop = scrollTop;
                return;
            }
            let direction = 'up';
            if (prevScrollTop) {
                direction = scrollTop > prevScrollTop ? 'down' : 'up';
            }
            // Make sticky?
            const { top } = main.getBoundingClientRect();
            if (isSticky) {
                if (top >= 0) {
                    unstick();
                    show(false);
                    return;
                }
                if (!force && Math.abs(scrollTop - prevScrollTop) <= 10) {
                    return;
                }
                if (direction === 'down' && top <= -height) {
                    hide(tween);
                } else if (direction === 'up') {
                    show(tween);
                }
            } else if (top <= -height) {
                stick();
                hide(false);
                return;
            }
            prevScrollTop = scrollTop;
        }, 0);
    };

    const onResize = (updateScroll = true, force = false) => {
        if (!force && Viewport.width === viewW && Math.abs(Viewport.height - viewH) < 150) {
            return;
        }
        viewW = Viewport.width;
        viewH = Viewport.height;
        height = $(inner).height();
        if (updateScroll) {
            onScroll();
        }
    };

    const onMenuOpen = () => {
        menuOpen = true;
        el.classList.add('menu-open');
    };

    const onMenuClose = () => {
        menuOpen = false;
        el.classList.remove('menu-open');
    };

    const setColor = newColor => {
        const currentColor = getCurrentColor();
        if (newColor === currentColor) {
            return;
        }
        const bgClasses = bg.classList;
        bgClasses.remove(currentColor);
        bgClasses.add(newColor);
    };

    const onBgColorChange = (key, { color }) => {
        bgColor = color;
        const newColor = (isSticky && !heroIntersecting) ? (bgColor || defaultColor) : defaultColor;
        setColor(newColor);
    };

    const init = () => {
        Viewport.on('scroll', onScroll);
        Viewport.on('resize', onResize);
        Dispatch.emit(COMPONENT_INIT);
        Dispatch.on(MENU_OPEN, onMenuOpen);
        Dispatch.on(MENU_CLOSE, onMenuClose);
        Dispatch.on(BG_COLOR_CHANGE, onBgColorChange, true);
        const hero = $('.hero').get(0);
        if (hero) {
            heroObserver = new IntersectionObserver(([{ isIntersecting }]) => {
                heroIntersecting = isIntersecting;
                onBgColorChange(null, { color: bgColor });
                if (heroIntersecting) {
                    unminify();
                } else {
                    minify();
                }
            });
            heroObserver.observe(hero);
        }
        onResize(false, true);
        onScroll(false, true);
    };

    const destroy = () => {
        Viewport.off('scroll', onScroll);
        Viewport.off('resize', onResize);
        Dispatch.off(MENU_OPEN, onMenuOpen);
        Dispatch.off(MENU_CLOSE, onMenuClose);
        Dispatch.off(BG_COLOR_CHANGE, onBgColorChange);
        if (heroObserver) {
            heroObserver.disconnect();
        }
    };

    return {
        init,
        destroy
    };

};
