import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default el => {

    const input = $(el).find('input[type="search"]').get(0);

    const onFocusIn = e => {
        input.select();
    };

    const init = () => {
        input.addEventListener('focusin', onFocusIn);
        if (document.activeElement === input) {
            input.select();
        }
        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        input.removeEventListener('focusin', onFocusIn);
    };

    return {
        init,
        destroy
    };

};
