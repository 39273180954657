import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';

export default el => {

    const $el = $(el);

    const onClick = e => {
        e.preventDefault();
        const toggle = e.currentTarget;
        const target = toggle.nextElementSibling;
        const doExpand = toggle.getAttribute('aria-expanded') === 'false';
        if (doExpand) {
            target.hidden = false;
            toggle.setAttribute('aria-expanded', 'true');
            $(toggle).find('svg').get(0).classList.add('hidden');
        } else {
            target.hidden = true;
            toggle.setAttribute('aria-expanded', 'false');
            $(toggle).find('svg').get(0).classList.remove('hidden');
        }
    };

    const init = () => {

        $el.find('button[aria-expanded]').on('click', onClick);

        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        $el.find('button[aria-expanded]').off('click', onClick);
    };

    return {
        init,
        destroy
    };

};
