import gsap from 'gsap';
import $ from '../core/Dom';
import Viewport from '../core/Viewport';
import Revealer from './Revealer';
import Dispatch from '../core/Dispatch';
import { UNLOAD } from './events';

const getRevealedTweens = () => $('[data-revealed]')
    .get()
    .reduce((carry, el) => {
        const tween = Viewport.visible(el) ? Revealer.tweens.get(el) : null;
        if (!tween) {
            return carry;
        }
        tween.pause(tween.time());
        return carry.concat(tween);
    }, []);

const isKeyCombo = e => e && (e.which === 2 || e.button === 4 || e.metaKey || e.shiftKey);

const clickHandler = e => {

    const { triggerTarget: target } = e;
    const href = target.getAttribute('href');
    const host = window.top.location.host.split(':')[0];

    // Check if the click was eligble for transition
    if (href[0] === '#' || target.getAttribute('target') === '_blank' || isKeyCombo(e) || target.hasAttribute('download') || href.indexOf(host) === -1 || href.indexOf('actions/debug') > -1) {
        return true;
    }

    e.preventDefault();

    Dispatch.emit(UNLOAD);

    // Rewind revealed tweens
    const revealedTweens = getRevealedTweens();
    if (revealedTweens.length) {
        gsap
            .to(revealedTweens, {
                progress: 0,
                duration: 0.5,
                stagger: 0.02,
                ease: 'none'
            });
    }

    gsap.timeline()
        // Tween the document background to white
        .to([document.documentElement, document.body], {
            backgroundColor: 'white',
            duration: 0.29,
            ease: 'Quad.easeIn'
        }, 0)
        // Tween out the body opacity
        .to(document.body, {
            opacity: 0,
            duration: 0.29,
            ease: 'Quad.easeIn'
        }, 0);

    // Redirect
    setTimeout(() => {
        window.location.href = href;
    }, 300);

    return false;
};

const initLinks = () => {
    $('body')
        .on('click', 'a', clickHandler);
};

const init = () => {

    initLinks();

    // Fix for Safari's back button (back-forward cache)
    window.onpageshow = e => {
        if (e.persisted) {
            initLinks();
            setTimeout(() => {
                // Reset revealed tweens
                gsap.set([document.documentElement, document.body], { clearProps: 'opacity' });
                const revealedTweens = getRevealedTweens();
                gsap.set(revealedTweens, { progress: 1 });
            }, 100);
        }
    };
};

export default ({
    init
});
