import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import { BG_COLOR_CHANGE } from './events';

const init = () => {

    const colorNodes = Array.from(document.querySelectorAll('[data-color]'));

    const bodyClasses = document.body.classList;

    const getCurrentColor = () => Array.from(bodyClasses).filter(className => className.startsWith('bg-'))[0] || null;

    const defaultColor = getCurrentColor();

    let prevScrollTop = -99999999999;

    const changeColor = (color = null) => {
        Dispatch.emit(BG_COLOR_CHANGE, { color: color || null });
        const newColor = color || defaultColor;
        const currentColor = getCurrentColor();
        if (newColor === currentColor) {
            return;
        }
        bodyClasses.remove(currentColor);
        bodyClasses.add(newColor);
    };

    const colorObserver = new IntersectionObserver(entries => {

        const { scrollTop } = Viewport;

        const direction = prevScrollTop > scrollTop ? 'up' : 'down';

        prevScrollTop = scrollTop;

        const intersecting = entries.reduce((carry, entry) => (entry.isIntersecting && entry.intersectionRatio >= 0.5 ? carry.concat(entry.target) : carry), []);

        if (intersecting.length) {
            changeColor(intersecting[0].dataset.color);
            return;
        }

        const nodes = direction === 'down' ? [].concat(colorNodes).reverse() : colorNodes;
        for (let i = 0; i < nodes.length; i += 1) {
            if (Viewport.visible(nodes[i])) {
                changeColor(nodes[i].dataset.color);
                return;
            }
        }

        changeColor(null);

    }, {
        //rootMargin: '-10% 0% -10% 0%',
        threshold: [0, 0.5]
    });

    colorNodes.forEach(colorNode => {
        colorObserver.observe(colorNode);
    });

};

export default {
    init
};
