import $ from '../core/Dom';
import { scrollTo } from './helpers';

const onClick = e => {
    e.preventDefault();
    const { triggerTarget: link } = e;
    const targetId = link.getAttribute('href');
    const target = $(targetId).get(0);
    if (target) {
        scrollTo(target);
    }
};

const init = () => {
    $('body').on('click', 'a[href^="#"]', onClick);
};

export default {
    init
};
