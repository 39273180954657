import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import Viewport from '../core/Viewport';
import { COMPONENT_INIT, MENU_CLOSE, MENU_OPEN, PROGRAMMATIC_SCROLL_START, UNLOAD } from '../lib/events';

export default el => {

    const $el = $(el);
    const menuBtn = $el.find('button[aria-expanded]')
        .get(0);
    const menu = menuBtn.nextElementSibling;
    const items = menu.firstElementChild;

    let menuExpanded = false;

    const collapseMenu = (tween = true) => {
        if (!menuExpanded) {
            return;
        }

        menuExpanded = false;
        menuBtn.setAttribute('aria-expanded', 'false');

        const afterClose = () => {
            menu.classList.add('hidden');
            gsap.killTweensOf([menu, items]);
            gsap.set([menu, items], { clearProps: 'opacity,transform' });
            Dispatch.emit(MENU_CLOSE);
        };

        if (!tween) {
            afterClose();
            return;
        }

        gsap
            .timeline({
                onComplete: afterClose
            })
            .to(menu, {
                opacity: 0,
                duration: 0.3
            }, 0);

    };

    const expandMenu = () => {

        if (menuExpanded) {
            return;
        }

        menuExpanded = true;
        menu.classList.remove('hidden');
        menuBtn.setAttribute('aria-expanded', 'true');

        gsap.killTweensOf([menu, items]);

        gsap
            .timeline()
            .fromTo(menu, { opacity: 0 }, {
                opacity: 1,
                duration: 0.3
            }, 0)
            .fromTo(items, { x: -10 }, {
                x: 0,
                duration: 0.5,
                ease: 'Quad.easeOut'
            }, 0)
            .set([menu, items], { clearProps: 'opacity,transform' });

        Dispatch.emit(MENU_OPEN);
    };

    const toggleMenu = () => {
        if (!menuExpanded) {
            expandMenu();
        } else {
            collapseMenu();
        }
    };

    const onBreakpoint = () => {
        if (!menuExpanded) {
            return;
        }
        if (!menuBtn.offsetParent) {
            // Burger is hidden, i.e. we're on a wider breakpoint and the "mobile" menu is n/a
            collapseMenu(false);
        }
    };

    const onProgrammaticScroll = () => {
        collapseMenu();
    };

    const onUnload = () => {
        collapseMenu();
    };

    const init = () => {
        Viewport.on('breakpoint', onBreakpoint);
        menuBtn.addEventListener('click', toggleMenu);
        Dispatch.on(PROGRAMMATIC_SCROLL_START, onProgrammaticScroll);
        Dispatch.on(UNLOAD, onUnload);
        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        Viewport.off('breakpoint', onBreakpoint);
        menuBtn.removeEventListener('click', toggleMenu);
        Dispatch.off(PROGRAMMATIC_SCROLL_START, onProgrammaticScroll);
        Dispatch.off(UNLOAD, onUnload);
        collapseMenu(false);
    };

    return {
        init,
        destroy
    };

};
