import gsap from 'gsap';
import Viewport from './core/Viewport';
import Components from './core/Components';
import lazySizes from './lib/lazysizes';
import Revealer from './lib/Revealer';
import Transition from './lib/Transition';
import BgColor from './lib/BgColor';
import Anchors from './lib/Anchors';
import { isLivePreview, reduceMotion } from './lib/helpers';
import Dispatch from './core/Dispatch';
import { COMPONENT_INIT } from './lib/events';

gsap.defaults({ overwrite: 'auto' });

const init = () => {

    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    lazySizes.init();
    Anchors.init();

    if (!reduceMotion()) {

        document.documentElement.classList.remove('reduce-motion');

        BgColor.init();

        if (!isLivePreview()) {
            Revealer.init();
            Transition.init();

            if (ENV !== 'production') {
                setTimeout(() => {
                    Dispatch.on(COMPONENT_INIT, () => {
                        setTimeout(Revealer.update, 0);
                    });
                }, 0);
            }
        }

    }

};

require('doc-ready')(init);
