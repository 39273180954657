import gsap from 'gsap';
import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { COMPONENT_INIT } from '../lib/events';
import Viewport from '../core/Viewport';

export default el => {

    const $el = $(el);

    const button = $el.find('button[aria-expanded]')
        .get(0);

    const items = button.nextElementSibling;

    let observer;
    let isExpanded = false;

    const collapse = (tween = true) => {
        if (!isExpanded) {
            return;
        }
        isExpanded = false;
        button.setAttribute('aria-expanded', 'false');
        const afterCollapse = () => {
            items.hidden = true;
        };
        if (!tween) {
            gsap.set(items, { height: 0 });
            el.classList.remove('is-expanded');
            afterCollapse();
            return;
        }
        gsap.timeline()
            .to(items, {
                height: 0,
                transformOrigin: 'center top',
                duration: 0.3,
                ease: 'Quint.easeOut',
                onComplete() {
                    afterCollapse();
                }
            }, 0)
            .add(() => {
                el.classList.remove('is-expanded');
            }, 0.15);
    };

    const expand = () => {
        if (isExpanded) {
            return;
        }
        isExpanded = true;
        items.hidden = false;
        button.setAttribute('aria-expanded', 'true');
        el.classList.add('is-expanded');
        gsap.timeline()
            .fromTo(items, { height: 0 }, {
                height: 'auto',
                transformOrigin: 'center top',
                duration: 0.3,
                ease: 'Quint.easeOut'
            }, 0);
    };

    const onButtonClick = () => {
        if (isExpanded) {
            collapse();
        } else {
            expand();
        }
    };

    const onBreakpoint = () => {
        if (!button.offsetParent) {
            collapse(false);
        }
    };

    const onBodyClick = e => {
        if (!isExpanded || e.target === button || e.target === items || button.contains(e.target) || items.contains(e.target)) {
            return;
        }
        collapse();
    };

    const onObserve = ([{ isIntersecting }]) => {
        if (!isIntersecting) {
            collapse(false);
        }
    };

    const init = () => {
        Viewport.on('breakpoint', onBreakpoint);
        button.addEventListener('click', onButtonClick);
        document.body.addEventListener('click', onBodyClick);
        observer = new IntersectionObserver(onObserve);
        observer.observe(items);
        Dispatch.emit(COMPONENT_INIT);
    };

    const destroy = () => {
        Viewport.off('breakpoint', onBreakpoint);
        button.removeEventListener('click', onButtonClick);
        document.body.removeEventListener('click', onBodyClick);
        if (observer) {
            observer.disconnect();
        }
    };

    return {
        init,
        destroy
    };

};
