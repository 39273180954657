import $ from '../core/Dom';

const VIDEO_TIMEOUT = 1000;

export default (el, { sources, timeout = VIDEO_TIMEOUT }) => {

    const $el = $(el);

    let timer;
    let observer;

    const clearTimer = () => {
        if (!timer) {
            return;
        }
        clearTimeout(timer);
        timer = null;
    };

    const cantPlay = () => {
        el.hidden = true;
        clearTimer();
        $(el).parent().find('img.lazyload').addClass('lazypreload');
        $(el).parent().find('picture[hidden],img[hidden]').each(img => {
            img.hidden = false;
        });
    };

    const setTimer = () => {
        clearTimer();
        timer = setTimeout(cantPlay, timeout);
    };

    const onTimeUpdate = e => {
        if (!timer) {
            return;
        }
        const { currentTime } = e.target;
        if (!!currentTime && currentTime > 0.001) {
            clearTimer();
        }
    };

    const onLoadStart = () => {
        if (!timer) {
            return;
        }
        setTimer();
    };

    const playAndCatch = () => {
        try {
            const promise = el.play();
            if (promise !== undefined) {
                promise.then(() => {
                    clearTimer();
                }).catch(e => {
                    if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
                        cantPlay();
                    }
                });
            }
        } catch (error) {
            console.error(error);
            cantPlay();
        }
    };

    const swapSource = ({ src }) => {
        if ($el.attr('src') === src) {
            return;
        }
        $el.attr('src', src);
        playAndCatch();
    };

    const doInit = () => {
        if (sources.length > 1) {
            sources.forEach(source => {
                const mq = window.matchMedia(source.media);
                const onChange = e => {
                    if (e.matches) {
                        swapSource(source);
                    }
                };
                try {
                    mq.addEventListener('change', onChange);
                } catch (error) {
                    mq.addListener(onChange);
                }
                // Sets initial breakpoint
                if (mq.matches) {
                    swapSource(source);
                }
            });
        } else {
            swapSource(sources[0]);
        }
        setTimer();
    };

    const onObserve = ([{ isIntersecting }]) => {
        if (!isIntersecting) {
            return;
        }
        doInit();
        observer.unobserve(el);
    };

    const init = () => {
        if (!el.canPlayType || !el.canPlayType('video/mp4')) {
            cantPlay();
            return;
        }
        el.addEventListener('loadstart', onLoadStart);
        el.addEventListener('loadedmetadata', onLoadStart);
        el.addEventListener('loadeddata', onLoadStart);
        el.addEventListener('canplay', onLoadStart);
        el.addEventListener('timeupdate', onTimeUpdate);
        observer = new IntersectionObserver(onObserve);
        observer.observe(el);
    };

    const destroy = () => {
        el.removeEventListener('loadstart', onLoadStart);
        el.removeEventListener('loadedmetadata', onLoadStart);
        el.removeEventListener('loadeddata', onLoadStart);
        el.removeEventListener('canplay', onLoadStart);
        el.removeEventListener('timeupdate', onTimeUpdate);
        if (observer) {
            observer.disconnect();
        }
    };

    return {
        init,
        destroy
    };

};
